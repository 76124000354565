@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.staggered-item {
  opacity: 0;
  animation: fadeInUp 0.5s forwards;
}

.staggered-item:nth-child(1) {
  animation-delay: 0.2s;
}

.staggered-item:nth-child(2) {
  animation-delay: 0.4s;
}

.staggered-item:nth-child(3) {
  animation-delay: 0.6s;
}

.staggered-item:nth-child(4) {
  animation-delay: 0.8s;
}

